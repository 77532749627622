<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle>
        {{ name }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: [
    'name'
  ]
}
</script>

<style lang="scss" scoped>

</style>
